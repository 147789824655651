import { useCallback, useState } from 'react'
import clsx from 'clsx'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { IconButton } from '~ui/IconButton'
import { Pill } from '~ui/Pill'
import { Price } from './Price'
import {
  AcceptBlueSubscription,
  StripeSubscription,
} from '~common/generated/storefront-graphql'
import { SubscriptionPrice } from '~common/components/product/SubscriptionPrice'
import { customFormat } from '~common/utils/format/percentageFormat'
import { capitalizeFirstLetter } from '~common/utils/format/capitalizeFirstLetter'
import { formatPrice } from '~common/utils/format/priceFormat'
import {
  ProductVariantCardProps,
  SubscriptionInterval,
  SubscriptionSettings,
} from './types'
import { SUBSCRIPTIONS_PIF } from './constants'
import { isBefore } from 'date-fns'
import { userDateFormat } from '~common/utils/dateHelper'

const getDuration = (duration: string) => {
  switch (duration) {
    case 'month':
      return 'mth'
    case 'week':
      return 'wk'
    case 'bi-weekly':
      return 'bi-wk'
    case 'year':
      return 'yr'
  }
}

const percentageFormat = customFormat({
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const convertStripeToGeneric = (
  subscription: StripeSubscription | AcceptBlueSubscription,
): SubscriptionSettings => {
  return {
    interval: subscription.recurring
      .interval as unknown as SubscriptionInterval,
    intervalCount: subscription.recurring.intervalCount,
    amountDueNow: subscription.amountDueNow,
    startDate: subscription.recurring.startDate,
    endDate: subscription.recurring.endDate,
  }
}

export const getDurationText = (subscription: SubscriptionSettings) => {
  let interval = subscription.interval
  let intervalCount = subscription.intervalCount
  // options[1] is the duration option group; options0[0] is the payment frequency
  return (
    <>
      {intervalCount > 0 ? (
        <div
          className={clsx(
            'w-20 h-20 p-4 mx-2 rounded-md flex flex-col justify-center items-center',
            {
              'bg-orange-300 bg-opacity-10 dark:bg-opacity-40':
                interval === SubscriptionInterval.biweekly,
              'bg-green-300 bg-opacity-10 dark:bg-opacity-40':
                interval === SubscriptionInterval.week,
              'bg-fuchsia-200 bg-opacity-20 dark:bg-opacity-40':
                interval === SubscriptionInterval.month,
              'bg-yellow-200 bg-opacity-10 dark:bg-opacity-40':
                interval === SubscriptionInterval.year,
            },
          )}
        >
          <span className="font-bold text-4xl text-black dark:text-slate-300">
            {intervalCount}
          </span>
          <span className="font-bold text-xs text-center text-black dark:text-slate-400">
            {capitalizeFirstLetter(interval)}
          </span>
        </div>
      ) : (
        <div className="w-20 h-20 p-4 mx-2 rounded-md bg-sky-100 bg-opacity-5 flex flex-col justify-center items-center">
          <span className="font-bold text-xl text-primary-600">Trial</span>
        </div>
      )}
    </>
  )
}

export const ProductVariantCard: React.FC<ProductVariantCardProps> = ({
  productVariant,
  subscription,
  type,
  quantity,
  className,
  highlight,
  showRecurring = false,
  showSku = false,
  trial = false,
  trialRequired = false,
  checked = false,
  disabled = false,
  allowOpen = true,
  openByDefault = true,
  multiple,
  name,
  value,
  showCheckbox,
  showAverage,
  onClick,
}) => {
  const [open, setOpen] = useState<boolean>(openByDefault)
  const handleClick = useCallback(
    (e: React.SyntheticEvent<HTMLDivElement>) => {
      // e.stopPropagation()
      if (subscription) {
        const { interval, intervalCount, startDate, endDate } =
          subscription.recurring
        onClick(
          productVariant,
          type,
          subscription
            ? {
                interval: interval.toString() as SubscriptionInterval,
                intervalCount,
                startDate: startDate as unknown as string,
                endDate: endDate as unknown as string,
                amountDueNow: subscription.amountDueNow,
              }
            : undefined,
        )
      } else {
        onClick(productVariant, type)
      }
    },
    [checked, onClick],
  )
  const handleToggle = useCallback(
    (e: React.SyntheticEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      setOpen((isOpen) => !isOpen)
    },
    [onClick],
  )

  const subscriptionSchedule = subscription

  const customFields = productVariant.customFields
  let recurring = null
  let discountPill = null
  let originalPrice = null
  let durationFrequency = productVariant.isSubscription
    ? ''
    : 'One time payment'
  let priceColor = 'font-bold'

  if (
    (!subscriptionSchedule || subscriptionSchedule.amountDueNow) &&
    customFields?.originalPrice &&
    customFields?.showDiscountPercentage &&
    productVariant.priceWithTax < customFields.originalPrice
  ) {
    discountPill = (
      <Pill variant="warn">
        {percentageFormat(
          1 - productVariant.priceWithTax / customFields.originalPrice,
        )}{' '}
        OFF
      </Pill>
    )
    originalPrice = (
      <Price
        currencyCode={productVariant.currencyCode}
        priceWithTax={customFields.originalPrice}
        className={clsx('block line-through text-sm text-right', {
          'text-error-600': !checked,
          'text-error-200': checked,
        })}
      />
    )
    priceColor = 'font-bold'
  }

  let price = null

  if (subscriptionSchedule) {
    durationFrequency = 'One time payment'
    const interval = subscriptionSchedule.recurring.interval
    const intervalCount = subscriptionSchedule.recurring.intervalCount
    if (subscriptionSchedule.amountDueNow) {
      recurring = (
        <Pill>
          {formatPrice(
            productVariant.priceWithTax / intervalCount,
            productVariant.currencyCode,
          )}{' '}
          / {getDuration(interval)} avg
        </Pill>
      )
    }

    switch (interval) {
      case SubscriptionInterval.month:
        durationFrequency = `${intervalCount} Monthly payment${
          intervalCount > 1 ? 's' : ''
        }`
        break
      case SubscriptionInterval.week:
        durationFrequency = `${intervalCount} Weekly payment${
          intervalCount > 1 ? 's' : ''
        }`
        break
      case SubscriptionInterval.year:
        durationFrequency = `${intervalCount} Yearly payment${
          intervalCount > 1 ? 's' : ''
        }`
        break
    }

    recurring = (
      <Pill>
        {formatPrice(
          (productVariant.priceWithTax * intervalCount +
            subscriptionSchedule.amountDueNow) /
            intervalCount,
          productVariant.currencyCode,
        )}{' '}
        / {getDuration(interval)} avg
      </Pill>
    )
    price = (
      <SubscriptionPrice
        currencyCode={productVariant.currencyCode}
        interval={subscriptionSchedule.recurring.interval}
        downpayment={subscriptionSchedule.amountDueNow}
        priceWithTax={subscriptionSchedule.recurring.amount}
        quantity={subscriptionSchedule.recurring.intervalCount}
        paidUpFront={!subscriptionSchedule.recurring}
        highlight={checked}
        prefix
        className={priceColor}
      />
    )
  } else {
    price = (
      <Price
        currencyCode={productVariant.currencyCode}
        priceWithTax={productVariant.priceWithTax}
        className={priceColor}
      />
    )
  }

  return (
    <div
      className={clsx(
        'border hover:border-primary-400 rounded-lg p-1 py-2 relative',
        {
          'bg-gray-50 text-primary-contrast-500 border-primary-400 dark:bg-slate-800':
            checked,
          'text-inherit bg-white border-gray-400 dark:bg-slate-900 dark:border-slate-500':
            !checked,
          'cursor-pointer': !disabled,
        },
        className,
      )}
      onClick={handleClick}
    >
      <div className={clsx('flex justify-between items-start', {})}>
        <div
          className={clsx('grow-1 shrink-1 flex gap-1 py-2', {
            'pl-2': !showCheckbox,
          })}
        >
          {showCheckbox ? (
            <div className="mx-2 flex items-center">
              <input
                id={name}
                type={multiple ? 'checkbox' : 'radio'}
                checked={checked}
                name={name}
                value={value}
                disabled={disabled}
                onChange={() => {}}
                className={clsx(
                  'w-4 h-4 focus:ring-2',
                  'text-primary-600 border-gray-300 focus:ring-gray-200',
                  'dark:focus:ring-gray-300 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600',
                )}
                tabIndex={-1}
              />
            </div>
          ) : null}
          {subscriptionSchedule
            ? getDurationText(convertStripeToGeneric(subscriptionSchedule))
            : null}
          <div className="flex justify-start flex-col items-start">
            <p className={clsx('font-semibold text-ellipsis line-clamp-2')}>
              {productVariant.name}
            </p>
            {customFields?.startDate ? (
              <p className="text-xs text-gray-800 dark:text-slate-400">
                {isBefore(customFields.startDate, new Date())
                  ? 'Started'
                  : 'Starts'}{' '}
                {userDateFormat(customFields.startDate)}
              </p>
            ) : null}
            {showSku ? (
              <p
                className={clsx('text-sm sm:block', {
                  'text-gray-500 dark:text-slate-300': checked,
                  'text-gray-600 dark:text-slate-400': !checked,
                })}
              >
                {productVariant.sku}
              </p>
            ) : null}
            <p
              className={clsx({
                'text-sm text-gray-500 dark:text-slate-300': checked,
                'text-sm text-gray-600 dark:text-slate-400': !checked,
              })}
            >
              {durationFrequency}
            </p>
          </div>
        </div>
        <div className="hidden sm:block shrink-0">{discountPill}</div>

        <div className="flex flex-col justify-end items-end">
          <div className="flex flex-row justify-end flex-wrap">
            <div>
              {trial && (
                <Pill variant="primary" className="">
                  Trial
                </Pill>
              )}
              {trialRequired && (
                <Pill variant="secondary" className="">
                  Trial required
                </Pill>
              )}
            </div>
            {highlight && <Pill variant="highlight">{highlight}</Pill>}
          </div>

          <div className="flex items-center min-height shrink-0">
            <div
              className={clsx('hidden sm:block px-2 shrink-0', {
                'pr-3': !allowOpen,
              })}
            >
              {price}
              {originalPrice}
            </div>
            {allowOpen && (
              <div className="shrink-0 w-7 pr-1 flex items-center justify-center">
                {(productVariant.customFields?.packageDescription?.length ||
                  0) > 3 && (
                  <IconButton
                    className={clsx(
                      'ml-auto shrink-0 transition-transform duration-200 ease-in-out motion-reduce:transition-none',
                      {
                        'rotate-0': !open,
                        'rotate-[90deg] -mr-1': open,
                      },
                    )}
                    icon={<ChevronRightIcon className="w-5 h-5" />}
                    onClick={handleToggle}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between sm:hidden mt-2">
        <div className="block sm:hidden">{discountPill}</div>
        <div
          className={clsx('block sm:hidden text-lg font-medium px-2 shrink-0', {
            'pr-8': allowOpen,
            'pr-3': !allowOpen,
          })}
        >
          {price}
          {originalPrice}
        </div>
      </div>

      <div
        className={clsx(
          'bg-white/60 dark:bg-slate-800/60 border-0 rounded-2xl dark:rounded-md absolute left-0 right-0 top-0 bottom-0 ',
          {
            hidden: !disabled,
          },
        )}
      />

      {showAverage && recurring ? (
        <div className="absolute -top-5 left-0 right-0 hidden sm:flex justify-end pr-1.5    ">
          {recurring}
        </div>
      ) : null}

      {productVariant.customFields?.packageDescription && (
        <div
          // data-to-collapse-item
          // data-to-collapse-show
          // data-to-parent="#product-variant"
          className={clsx(
            'overflow-scroll pt-2 pr-3 max-h-0 transition-[height] ease-in-out delay-50 duration-500 text-sm text-gray-600',
            {
              'max-h-[200px]': open,
              'text-primary-contrast': checked,
            },
          )}
          dangerouslySetInnerHTML={{
            __html: productVariant.customFields.packageDescription
              .replace(/\<ul\>/g, '<ul class="list-disc list-outside ml-4">')
              .replace(/\<p\>/g, '<p class="my-2">'),
          }}
        />
      )}
    </div>
  )
}
