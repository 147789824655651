/**
 * product or product variant: ages: 4-5 (little ninjas), 6-7 (young achievers), 8-12 (kids), 13+
 */
export const AGE_FACET_CODE = 'ages'
/**
 * product: trial: trial-required
 * product variant: trial: is-trial
 */
export const TRIAL_FACET_CODE = 'trial'
export const TRIAL_REQUIRED_FACET_CODE = 'trial-required'
export const IS_TRIAL_FACET_CODE = 'is-trial'

/**
 * product: allows-multiple user can select multiple variants together
 * product variant: can-combine check if this variant can be combined(selected) with other variants or not
 */
export const ALLOWS_MULTIPLE_FACET_CODE = 'multiple'
export const CAN_COMBINE_FACET_CODE = 'combine'

/**
 * product or product variant: featured: featured
 */
export const FEATURED_FACET_CODE = 'featured'
/**
 * product: class: is-class
 */
export const CLAZZ_FACET_CODE = 'class'
export const IS_CLAZZ_FACET_CODE = 'is-class'
/**
 * subscriptions: weekly, bi-weekly, monthly, yearly, pif
 */
export const SUBSCRIPTIONS = 'subscriptions'
export const IS_SUBSCRIPTION = 'is-subscription'
export const SUBSCRIPTIONS_WEEKLY = 'weekly'
export const SUBSCRIPTIONS_BIWEEKLY = 'bi-weekly'
export const SUBSCRIPTIONS_MONTHLY = 'monthly'
export const SUBSCRIPTIONS_PIF = 'paid-in-full' // cycle date => pay the whole product until end subscription date
/**
 * Disable customer edit
 */
export const DISABLE_EDIT_FV_CODE = 'disable-edit'