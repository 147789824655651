import {
  Order,
  OrderLine,
  ProductVariant,
} from '~common/generated/admin-graphql'
import { userDateFormat } from './dateHelper'
import { format, isBefore } from 'date-fns'

export const getLineDateRange = (line: OrderLine): string | undefined => {
  const { productVariant, subscriptionDetails } = line
  if (!subscriptionDetails) {
    return
  }
  const { startDate, endDate } = subscriptionDetails
  const { product, customFields: productVariantCustomFields } =
    productVariant ?? {}

  if ((!startDate || isBefore(startDate, new Date())) && !endDate) {
    return
  }

  return `week of ${format(new Date(startDate), 'MMM d, yyyy')}`

  // return `${format(new Date(startDate), 'MMM d')}${endDate ? `-${format(new Date(endDate), 'MMM d, yyyy')}` : ''}`
}

export const sortLinesByProductVariant = (data: Order): Order => {
  if (data.lines) {
    data.lines.sort((a, b) => {
      if (
        a.productVariant.customFields?.startDate &&
        b.productVariant.customFields?.startDate
      ) {
        return new Date(a.productVariant.customFields.startDate) <
          new Date(b.productVariant.customFields.startDate)
          ? -1
          : 1
      }
      return a.productVariant.name.localeCompare(b.productVariant.name)
    })
  }
  return data
}

export const sortProductVariants = (variants: ProductVariant[]) => {
  return variants.sort((a, b) => {
    if (a.customFields?.startDate && b.customFields?.startDate) {
      return new Date(a.customFields.startDate) <
        new Date(b.customFields.startDate)
        ? -1
        : 1
    }
    return a.name.localeCompare(b.name)
  })
}
